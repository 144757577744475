<template>
  <div>
    <v-img :src="cover" height="250" aspect-ratio="16/9">
      <div class="d-flex justify-center align-center backdrop-grad">
        <div class="display-3 white--text">GALERY</div>
      </div>
    </v-img>

    <v-container class="my-6">
      <v-tabs v-model="tab" centered @change="changeTab()">
        <v-tab>Video</v-tab>
        <v-tab>Image</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="mt-6">
        <v-tab-item class="py-4">
          <v-row v-if="videos.length > 0" align="stretch">
            <v-col cols="12" sm="4" v-for="item in videos" :key="item.id">
              <v-card style="height: 100%">
                <youtube
                  v-if="tab === 0"
                  :video-id="sliceUrl(item.content)"
                  :player-vars="playerVars"
                  style="max-width: 100%"
                ></youtube>
                <v-card-title
                  style="word-break: break-word"
                  class="max-length-title"
                  >{{ item.title }}</v-card-title
                >
                <v-card-subtitle
                  v-html="item.description"
                  class="max-length"
                ></v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <div v-else class="text-center">No video available</div>
        </v-tab-item>
        <v-tab-item class="py-4">
          <v-row v-if="galeries.length > 0" align="stretch">
            <v-col cols="12" sm="4" v-for="item in galeries" :key="item.id">
              <v-card class="mx-auto" max-width="344" height="100%">
                <v-img
                  v-if="tab === 1"
                  :src="item.content"
                  height="200px"
                ></v-img>
                <v-card-title
                  style="word-break: break-word"
                  class="max-length-title"
                  >{{ item.title }}</v-card-title
                >
                <v-card-subtitle>
                  <span v-if="!item.description">-</span>
                  <span
                    v-else
                    v-html="item.description"
                    class="max-length"
                  ></span>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <div v-else class="text-center">No image available</div>
        </v-tab-item>
      </v-tabs-items>

      <div class="d-flex justify-end">
        <a
          v-if="last_page != current_page && last_page >= current_page"
          class="primary--text"
          @click="viewMoreHandler"
          >View more</a
        >
      </div>
    </v-container>
  </div>
</template>

<script>
import { getGallery } from "@/api/master";

export default {
  data() {
    return {
      tab: 0,
      galeries: [],
      videos: [],
      query: {
        limit: 9,
        page: 1,
      },
      current_page: 1,
      last_page: 1,
      playerVars: {
        autoplay: 0,
      },
      cover: "",
    };
  },
  async mounted() {
    await this.getData();
    this.cover = this.$store.getters.g_profile.home_cover;
  },
  methods: {
    changeTab() {
      this.query.page = 1;
      this.getData();
    },
    viewMoreHandler() {
      this.query.page += 1;
      this.getData("viewMore");
    },
    sliceUrl(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : "";
    },
    getData(viewMore) {
      let type = "VIDEO";
      if (this.tab) type = "IMAGE";

      getGallery({
        ...this.query,
        content_type: type,
      }).then((res) => {
        const { code, data } = res.data;
        if (code) {
          if (type === "VIDEO") {
            if (viewMore) {
              data.data.forEach((d) => {
                this.videos.push(d);
              });
            } else this.videos = data.data;
          }
          if (type === "IMAGE") {
            if (viewMore) {
              data.data.forEach((d) => {
                this.galeries.push(d);
              });
            } else this.galeries = data.data;
          }
          this.current_page = data.current_page;
          this.last_page = data.last_page;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.max-length-title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: 3.3em; /* Fallback for non-webkit, line-height * 2 */
  line-height: 1.3em;
  -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}
.max-length {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: 5.1em; /* Fallback for non-webkit, line-height * 2 */
  line-height: 1.3em;
  -webkit-line-clamp: 4; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}
</style>
